import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconPriceline = createSvgIconComponent({
  viewBox: "0 0 217 120",
  fill: "none",
  children: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("rect", {
    fill: "white",
    height: "120",
    width: "216.471"
  }), ___EmotionJSX("g", {
    clipPath: "url(#icon-priceline-clip)"
  }, ___EmotionJSX("path", {
    d: "M105.646 58.9102V42.6631H108.721V44.0906C109.246 43.1847 110.556 42.4004 112.317 42.4004C115.748 42.4004 117.725 45.02 117.725 48.4984C117.725 52.0474 115.509 54.6671 112.199 54.6671C110.58 54.6671 109.388 54.0239 108.815 53.2357V58.9063H105.646V58.9102ZM111.697 45.2357C110.078 45.2357 108.768 46.4514 108.768 48.522C108.768 50.5925 110.078 51.8318 111.697 51.8318C113.317 51.8318 114.603 50.6161 114.603 48.522C114.603 46.4514 113.317 45.2357 111.697 45.2357Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M126.874 45.8076C126.517 45.737 126.207 45.7135 125.921 45.7135C124.301 45.7135 122.897 46.4978 122.897 49.0233V54.3841H119.729V42.6625H122.803V44.3997C123.517 42.8507 125.136 42.5645 126.136 42.5645C126.399 42.5645 126.638 42.588 126.874 42.6115V45.8076Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M130.277 36.8516C131.371 36.8516 132.23 37.7339 132.23 38.828C132.23 39.8751 131.371 40.7574 130.277 40.7574C129.207 40.7574 128.324 39.8751 128.324 38.828C128.324 37.7339 129.207 36.8516 130.277 36.8516ZM128.705 54.3849V42.6633H131.873V54.3849H128.705Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M137.046 48.5223C137.046 50.6439 138.426 51.8321 140.046 51.8321C141.665 51.8321 142.477 50.7615 142.736 49.8557L145.524 50.7851C144.999 52.738 143.167 54.738 140.046 54.738C136.591 54.738 133.877 52.1184 133.877 48.5223C133.877 44.9027 136.544 42.3066 139.951 42.3066C143.144 42.3066 144.955 44.2831 145.453 46.2596L142.618 47.2125C142.332 46.2361 141.595 45.2361 140.022 45.2361C138.402 45.2361 137.046 46.4047 137.046 48.5223Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M157.843 51.0243C157.247 53.0478 155.388 54.7419 152.506 54.7419C149.29 54.7419 146.455 52.4321 146.455 48.4753C146.455 44.7341 149.22 42.3066 152.22 42.3066C155.839 42.3066 158.008 44.6164 158.008 48.3812C158.008 48.8321 157.961 49.3106 157.961 49.3576H149.577C149.647 50.9066 150.957 52.0243 152.53 52.0243C154.008 52.0243 154.816 51.287 155.196 50.2361L157.843 51.0243ZM154.89 47.189C154.843 46.0204 154.079 44.8792 152.271 44.8792C150.628 44.8792 149.722 46.1184 149.651 47.189H154.89Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M160.082 54.3857V37.1387H163.251V54.3857H160.082Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M167.799 36.8516C168.893 36.8516 169.752 37.7339 169.752 38.828C169.752 39.8751 168.893 40.7574 167.799 40.7574C166.728 40.7574 165.846 39.8751 165.846 38.828C165.846 37.7339 166.728 36.8516 167.799 36.8516ZM166.226 54.3849V42.6633H169.395V54.3849H166.226Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M175.52 54.3849H172.352V42.6633H175.426V44.1182C176.14 42.9025 177.548 42.3535 178.806 42.3535C181.712 42.3535 183.046 44.4241 183.046 47.0006V54.3849H179.877V47.5496C179.877 46.2398 179.234 45.2163 177.708 45.2163C176.328 45.2163 175.516 46.2868 175.516 47.6476V54.3849H175.52Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M196.269 51.0243C195.673 53.0478 193.814 54.7419 190.932 54.7419C187.716 54.7419 184.881 52.4321 184.881 48.4753C184.881 44.7341 187.646 42.3066 190.646 42.3066C194.265 42.3066 196.434 44.6164 196.434 48.3812C196.434 48.8321 196.387 49.3106 196.387 49.3576H188.002C188.073 50.9066 189.383 52.0243 190.955 52.0243C192.434 52.0243 193.242 51.287 193.622 50.2361L196.269 51.0243ZM193.316 47.189C193.269 46.0204 192.504 44.8792 190.697 44.8792C189.053 44.8792 188.148 46.1184 188.077 47.189H193.316Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M105.65 81.8506V65.8662H107.819V67.6545C108.439 66.5094 109.795 65.6074 111.654 65.6074C115.086 65.6074 116.917 68.2506 116.917 71.5878C116.917 74.9957 114.941 77.6388 111.58 77.6388C109.815 77.6388 108.482 76.8035 107.862 75.78V81.8545H105.65V81.8506ZM111.246 67.58C109.199 67.58 107.839 69.2937 107.839 71.5839C107.839 73.9408 109.195 75.6349 111.246 75.6349C113.317 75.6349 114.631 73.9447 114.631 71.5839C114.631 69.2976 113.345 67.58 111.246 67.58Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M121.54 77.3252H119.301V60.0781H121.54V67.1997C122.375 65.984 123.709 65.533 125.018 65.533C127.76 65.533 129.116 67.5095 129.116 70.0585V77.3252H126.877V70.4389C126.877 68.8428 126.211 67.5566 124.211 67.5566C122.497 67.5566 121.591 68.8899 121.544 70.5801V77.3252H121.54Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M134.953 70.7725L138.051 70.3216C138.741 70.2274 138.933 69.8706 138.933 69.4627C138.933 68.3412 138.196 67.4157 136.455 67.4157C134.882 67.4157 134 68.3921 133.859 69.7255L131.714 69.2235C131.953 67.0314 133.93 65.5059 136.408 65.5059C139.839 65.5059 141.173 67.4588 141.173 69.698V75.4392C141.173 76.4392 141.267 77.0353 141.314 77.3215H139.122C139.075 77.0353 139.004 76.6078 139.004 75.7725C138.502 76.5843 137.361 77.6784 135.31 77.6784C132.977 77.6784 131.475 76.0588 131.475 74.247C131.475 72.2039 132.977 71.0588 134.953 70.7725ZM138.93 72.4157V71.8902L135.428 72.4157C134.475 72.5568 133.761 73.1059 133.761 74.1294C133.761 74.9882 134.475 75.7961 135.62 75.7961C137.455 75.8 138.93 74.9176 138.93 72.4157Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M150.509 68.1287C150.176 68.0817 149.843 68.0581 149.533 68.0581C147.65 68.0581 146.388 69.0581 146.388 71.5836V77.3248H144.148V65.866H146.341V67.866C147.176 66.1013 148.603 65.6738 149.772 65.6738C150.082 65.6738 150.392 65.7209 150.509 65.7444V68.1287Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M152.416 77.3254V65.8665H154.561V67.392C155.275 66.1293 156.706 65.5332 158.04 65.5332C159.467 65.5332 160.851 66.2234 161.471 67.7018C162.377 66.0587 163.855 65.5332 165.306 65.5332C167.306 65.5332 169.259 66.8901 169.259 69.894V77.3254H167.043V70.1332C167.043 68.6312 166.306 67.5136 164.612 67.5136C163.04 67.5136 161.969 68.7528 161.969 70.3959V77.3293H159.73V70.1371C159.73 68.6587 159.016 67.5175 157.298 67.5175C155.702 67.5175 154.632 68.7097 154.632 70.4234V77.3332H152.416V77.3254Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M175.117 70.7725L178.215 70.3216C178.905 70.2274 179.098 69.8706 179.098 69.4627C179.098 68.3412 178.36 67.4157 176.619 67.4157C175.047 67.4157 174.164 68.3921 174.023 69.7255L171.878 69.2235C172.117 67.0314 174.094 65.5059 176.572 65.5059C180.003 65.5059 181.337 67.4588 181.337 69.698V75.4392C181.337 76.4392 181.431 77.0353 181.478 77.3215H179.286C179.239 77.0353 179.168 76.6078 179.168 75.7725C178.666 76.5843 177.525 77.6784 175.474 77.6784C173.141 77.6784 171.639 76.0588 171.639 74.247C171.639 72.2039 173.141 71.0588 175.117 70.7725ZM179.098 72.4157V71.8902L175.596 72.4157C174.643 72.5568 173.929 73.1059 173.929 74.1294C173.929 74.9882 174.643 75.7961 175.788 75.7961C177.619 75.8 179.098 74.9176 179.098 72.4157Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M185.646 71.5843C185.646 74.2039 187.289 75.6117 189.148 75.6117C191.293 75.6117 192.077 74.1568 192.34 73.2549L194.316 74.1137C193.767 75.7568 192.124 77.6862 189.148 77.6862C185.814 77.6862 183.359 75.0666 183.359 71.5882C183.359 68.0156 185.861 65.5137 189.124 65.5137C192.175 65.5137 193.748 67.4196 194.222 69.1568L192.199 70.0156C191.912 68.8941 191.081 67.5843 189.124 67.5843C187.316 67.5803 185.646 68.9137 185.646 71.5843Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M196.748 82.1142L199.607 76.0633L194.678 65.8672H197.25L200.823 73.777L204.254 65.8672H206.658L199.203 82.1142H196.748Z",
    fill: "#1E1E1E"
  }), ___EmotionJSX("path", {
    d: "M78.0079 39.9844H70.004V67.9961C70.004 74.6275 64.6275 80.0001 58.0001 80.0001H29.9844V88.004C29.9844 94.6354 35.3608 100.008 41.9883 100.008H57.9961C64.6275 100.008 70.0001 94.6354 70.0001 88.004V80.0001H78.004C84.6354 80.0001 90.0079 74.6275 90.0079 67.9922V51.9844C90.0118 45.3569 84.6354 39.9844 78.0079 39.9844Z",
    fill: "#39E151"
  }), ___EmotionJSX("path", {
    d: "M70.004 67.9962V39.9844V31.9805C70.004 25.3491 64.6275 19.9727 58.0001 19.9727H41.9923C35.3609 19.9727 29.9883 25.3452 29.9883 31.9805V39.9844H21.9805C15.3491 39.9844 9.97656 45.357 9.97656 51.9883V67.9962C9.97656 74.6276 15.353 80.0001 21.9805 80.0001H29.9844H57.9962C64.6276 80.004 70.004 74.6276 70.004 67.9962Z",
    fill: "#FC52B1"
  })), ___EmotionJSX("defs", null, ___EmotionJSX("clipPath", {
    id: "icon-priceline-clip"
  }, ___EmotionJSX("rect", {
    fill: "white",
    height: "80",
    transform: "translate(10 20)",
    width: "196.471"
  }))))
});