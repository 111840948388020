import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["includePartners", "fontSize"];

var _templateObject;

import React from "react";
import { Box, Flex, IconBunningsWarehouse, IconCatch, IconFlybuys, IconInstantScripts, IconKmart, IconOfficeworks, IconPriceline, IconTarget, Span } from "@catchoftheday/cg-components";
import { styled } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
var FlexWrapper = styled(Flex)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  & {\n    gap: 0.75em;\n  }\n"])));
var commonIconProps = {
  width: "auto"
};
export var OnePassIconsGroup = function OnePassIconsGroup(_ref) {
  var includePartners = _ref.includePartners,
      _ref$fontSize = _ref.fontSize,
      fontSize = _ref$fontSize === void 0 ? "2xl" : _ref$fontSize,
      wrapperProps = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    fontSize: fontSize,
    flexWrap: "wrap"
  }, wrapperProps), ___EmotionJSX(FlexWrapper, {
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
  }, ___EmotionJSX(IconKmart, _extends({
    "aria-label": "Kmart",
    height: "1em"
  }, commonIconProps)), ___EmotionJSX(IconTarget, _extends({
    "aria-label": "Target",
    height: "1em"
  }, commonIconProps)), ___EmotionJSX(IconCatch, _extends({
    "aria-label": "Catch",
    height: "1em"
  }, commonIconProps)), ___EmotionJSX(FlexWrapper, {
    alignItems: "center"
  }, ___EmotionJSX(IconBunningsWarehouse, _extends({
    "aria-label": "Bunnings Warehouse",
    height: "1em"
  }, commonIconProps)), ___EmotionJSX(IconOfficeworks, _extends({
    "aria-label": "Officeworks",
    height: ".8em"
  }, commonIconProps))), ___EmotionJSX(FlexWrapper, {
    alignItems: "center"
  }, ___EmotionJSX(IconInstantScripts, _extends({
    "aria-label": "Instant Scripts",
    height: "1.5em"
  }, commonIconProps)), ___EmotionJSX(IconPriceline, _extends({
    "aria-label": "Priceline Pharmacy",
    height: "1.5em"
  }, commonIconProps)))), includePartners && ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Box, {
    width: "1px",
    mx: 5,
    height: "1.5em",
    backgroundColor: "onePass.primary"
  }), ___EmotionJSX(FlexWrapper, {
    alignItems: "center"
  }, ___EmotionJSX(Flex, {
    color: "onePass.primary",
    fontWeight: "semibold",
    fontSize: "xs",
    flexDirection: "column",
    alignItems: "center"
  }, ___EmotionJSX(Span, {
    fontWeight: "inherit"
  }, "OnePass"), ___EmotionJSX(Span, {
    fontWeight: "inherit"
  }, "Partners")), ___EmotionJSX(IconFlybuys, _extends({
    color: "brand.flybuys",
    height: "1em",
    viewBox: "0 0 70 23"
  }, commonIconProps)))));
};