import React from "react";
import { Box, Heading, IconOnePass, Link, LinkButton, List, ListItem, Text } from "@catchoftheday/cg-components";
import { rem } from "@catchoftheday/theme";
import { MigrationNudgeContentZone } from "../../layouts/MigrationNudgeLayout/MigrationNudgeLayout";
import { Divisions } from "./Divisions";
import { jsx as ___EmotionJSX } from "@emotion/react";
var TNC_BENEFITS_LINK = "https://e.catch.com.au/pub/cc?_ri_=X0Gzc2X%3DAQjkPkSSUQGzeNvzapTuzb6scDRIzgnfBazdnwJBsFzgzaS1zdrhJzeahzgezgEvfzdmB8pI6VXtpKX%3DUYSSUWD&_ei_=EW2tf9zs59idfPO1Sc_9Bbm-LofhhwiG5__s2K-4nd3YY3QX09SvC0dw99lFJ9RPImuBSde5-ire_lAko1daQsbpiOqq-g.&_di_=pjuto6hrailulu9f88p7s6objastv2hkdmh4k1esgdf7elpo2t20";
export var Content = function Content(_ref) {
  var CTAUrl = _ref.CTAUrl,
      onCTAClick = _ref.onCTAClick;
  return ___EmotionJSX(MigrationNudgeContentZone, {
    mb: 0
  }, ___EmotionJSX(IconOnePass, {
    "aria-label": "OnePass",
    color: "onePass.primary",
    height: rem("32px"),
    width: "auto",
    mb: 6
  }), ___EmotionJSX(Heading.h3, {
    fontWeight: "semibold",
    fontSize: {
      xs: "base",
      md: "2xl"
    },
    mb: 2,
    mt: 0,
    textAlign: "center"
  }, "Transfer your membership now"), ___EmotionJSX(Text, {
    fontSize: {
      xs: "sm",
      md: "base"
    },
    fontWeight: "normal",
    mb: {
      xs: 4,
      md: 6
    },
    mt: 0,
    textAlign: "center"
  }, "Club Catch closes April 30! Transfer your membership to OnePass today to keep your benefits.", ___EmotionJSX("br", null), "Don\u2019t wait - act now"), ___EmotionJSX(LinkButton, {
    "data-testid": "migration-nudge-cta",
    variant: "onePassPrimary",
    fontSize: "xs",
    rounded: true,
    fontWeight: "semibold",
    onClick: onCTAClick,
    href: CTAUrl,
    maxWidth: rem("240px"),
    mb: 4
  }, "Transfer your membership"), ___EmotionJSX(Divisions, null), ___EmotionJSX(Box, {
    my: 4,
    mx: "auto",
    maxWidth: {
      md: "90%"
    }
  }, ___EmotionJSX(Text, {
    m: 0,
    fontSize: "xs",
    fontWeight: "semibold",
    color: "onePass.textGrey"
  }, "Unlock these benefits with a OnePass membership"), ___EmotionJSX(List, {
    my: 0,
    ml: 3,
    type: "disc",
    fontSize: "xs",
    fontWeight: "semibold",
    color: "onePass.textGrey",
    style: {
      listStylePosition: "initial"
    }
  }, ___EmotionJSX(ListItem, null, "Free delivery^ on eligible items or orders with no minimum spend at Kmart, Target, Bunnings Warehouse, Officeworks, InstantScripts, Priceline and Catch.com.au (until 30th April 2025)."), ___EmotionJSX(ListItem, null, "Earn 5x Flybuys points^ for every $1 spent in-store and with Click and Collect at Kmart, Target, Bunnings Warehouse and Officeworks. Continue to collect 2x Flybuys points for every $2 spent at Catch (until 30th April 2025)."), ___EmotionJSX(ListItem, null, "365 Day change of mind returns^ - Enjoy peace of mind with 365-day returns - change your mind? No worries"), ___EmotionJSX(ListItem, null, "Express Click & Collect^ - Save time and grab your product fast at Kmart and Bunnings Warehouse"), ___EmotionJSX(ListItem, null, "Member Exclusives^ - Enjoy early access to exciting events throughout the year, including Black Friday and OnePass Weekend, across OnePass\u2019 participating brands."))), ___EmotionJSX(Text, {
    fontSize: "xs",
    m: 0,
    color: "onePass.textGrey",
    fontWeight: "normal"
  }, "^ T&Cs, Exclusions and eligibility criteria apply. Free delivery with no minimum spend. Not all participating brands offer all member benefits.", " ", ___EmotionJSX(Link, {
    href: TNC_BENEFITS_LINK,
    target: "__blank"
  }, "Click here for benefits and exclusions for each participating brand."), " ", "Catch benefits under the OnePass program only available until 30 April 2025."));
};