import React from "react";
import { createSvgIconComponent } from "../utils";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var IconInstantScripts = createSvgIconComponent({
  viewBox: "0 0 68 24",
  fill: "none",
  children: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("g", {
    clipPath: "url(#icon-instant-scripts-clip)"
  }, ___EmotionJSX("path", {
    d: "M16.7053 9.27001C16.5523 8.75278 16.2722 8.28211 15.8905 7.90094C15.5088 7.51977 15.0378 7.24025 14.5204 7.08788C14.1231 6.97408 13.7023 6.97098 13.3034 7.07891C12.9045 7.18683 12.5427 7.40169 12.257 7.70028L9.3963 10.5638L6.53279 7.70028C5.40534 6.57283 3.85072 6.94423 2.89811 7.90063C1.94549 8.85702 1.57503 10.4088 2.69776 11.5381L5.80509 14.6483C5.83753 14.6811 5.87616 14.7072 5.91874 14.725C5.96133 14.7428 6.00702 14.752 6.05317 14.752C6.09932 14.752 6.14501 14.7428 6.1876 14.725C6.23018 14.7072 6.26881 14.6811 6.30125 14.6483L9.3963 11.5561L12.4876 14.6483C12.52 14.6811 12.5586 14.7072 12.6012 14.725C12.6438 14.7428 12.6895 14.752 12.7356 14.752C12.7818 14.752 12.8275 14.7428 12.8701 14.725C12.9127 14.7072 12.9513 14.6811 12.9837 14.6483L16.0958 11.5391C16.3954 11.2529 16.6107 10.89 16.7182 10.4899C16.8257 10.0897 16.8212 9.66778 16.7053 9.27001ZM6.05459 13.9008L3.19107 11.0439C2.35187 10.2009 2.70721 9.08194 3.39143 8.39773C3.74199 8.03392 4.20205 7.79493 4.70127 7.71729C4.76709 7.70896 4.83338 7.70485 4.89973 7.705C5.11213 7.70624 5.32208 7.75041 5.51697 7.83486C5.71186 7.91931 5.88766 8.0423 6.0338 8.19643L8.89731 11.0599L6.05459 13.9008ZM15.5996 11.0373L12.7361 13.9008L9.89245 11.0656L12.756 8.19643C12.9547 7.98516 13.2081 7.83313 13.4881 7.75722C13.768 7.68131 14.0635 7.6845 14.3418 7.76643C14.7409 7.88736 15.104 8.10521 15.3985 8.40054C15.693 8.69588 15.9098 9.05952 16.0296 9.45902C16.1099 9.73643 16.112 10.0306 16.0356 10.3091C15.9592 10.5876 15.8074 10.8396 15.5968 11.0373H15.5996Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M9.39316 14.0218C9.67553 14.0218 9.90443 13.7928 9.90443 13.5105C9.90443 13.2281 9.67553 12.9992 9.39316 12.9992C9.11079 12.9992 8.88188 13.2281 8.88188 13.5105C8.88188 13.7928 9.11079 14.0218 9.39316 14.0218Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M9.39316 17.2283C9.67553 17.2283 9.90443 16.9994 9.90443 16.717C9.90443 16.4347 9.67553 16.2057 9.39316 16.2057C9.11079 16.2057 8.88188 16.4347 8.88188 16.717C8.88188 16.9994 9.11079 17.2283 9.39316 17.2283Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M10.9969 15.6245C11.2793 15.6245 11.5082 15.3956 11.5082 15.1133C11.5082 14.8309 11.2793 14.602 10.9969 14.602C10.7145 14.602 10.4856 14.8309 10.4856 15.1133C10.4856 15.3956 10.7145 15.6245 10.9969 15.6245Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M7.79035 15.6245C8.07272 15.6245 8.30162 15.3956 8.30162 15.1133C8.30162 14.8309 8.07272 14.602 7.79035 14.602C7.50798 14.602 7.27907 14.8309 7.27907 15.1133C7.27907 15.3956 7.50798 15.6245 7.79035 15.6245Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M18.752 14.138V9.83704C18.7494 9.7848 18.7571 9.73255 18.7747 9.68329C18.7922 9.63403 18.8194 9.58871 18.8545 9.54994C18.8896 9.51117 18.932 9.4797 18.9793 9.45734C19.0265 9.43497 19.0778 9.42215 19.13 9.4196C19.1823 9.41705 19.2345 9.42483 19.2837 9.44249C19.333 9.46015 19.3782 9.48735 19.4169 9.52252C19.4556 9.55769 19.487 9.60015 19.5093 9.64747C19.5316 9.69479 19.5444 9.74604 19.5468 9.79829C19.5478 9.81119 19.5478 9.82414 19.5468 9.83704V14.138C19.5518 14.2433 19.5147 14.3462 19.4439 14.4242C19.373 14.5022 19.274 14.5489 19.1688 14.5541C19.0635 14.5592 18.9605 14.5224 18.8823 14.4516C18.8042 14.3809 18.7573 14.282 18.752 14.1767C18.7511 14.1638 18.7511 14.1509 18.752 14.138Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M23.913 12.1591V14.138C23.9181 14.2424 23.8816 14.3446 23.8114 14.422C23.7412 14.4995 23.6431 14.5459 23.5387 14.551C23.4343 14.5561 23.3322 14.5196 23.2547 14.4494C23.1773 14.3792 23.1309 14.2812 23.1257 14.1768C23.1248 14.1639 23.1248 14.1509 23.1257 14.138V12.264C23.1257 11.7546 22.8035 11.4134 22.3234 11.4134C22.2159 11.4105 22.109 11.4289 22.0086 11.4673C21.9083 11.5058 21.8165 11.5637 21.7386 11.6377C21.6606 11.7117 21.598 11.8004 21.5544 11.8986C21.5107 11.9968 21.4869 12.1027 21.4842 12.2101C21.4832 12.228 21.4832 12.246 21.4842 12.264V14.138C21.4912 14.238 21.4582 14.3367 21.3924 14.4124C21.3267 14.4881 21.2336 14.5345 21.1336 14.5416C21.1191 14.5425 21.1046 14.5425 21.0901 14.5416C21.0387 14.5423 20.9877 14.5329 20.9399 14.5139C20.8922 14.495 20.8486 14.4668 20.8118 14.431C20.7749 14.3952 20.7455 14.3525 20.7251 14.3053C20.7048 14.2581 20.6939 14.2074 20.6932 14.156V11.1025C20.688 10.9991 20.7242 10.8979 20.7937 10.8212C20.8631 10.7444 20.9602 10.6984 21.0636 10.6933C21.167 10.6882 21.2682 10.7243 21.345 10.7938C21.4217 10.8632 21.4677 10.9604 21.4728 11.0638C21.4738 11.0767 21.4738 11.0896 21.4728 11.1025C21.7584 10.8326 22.1394 10.6868 22.5322 10.6971C23.3733 10.6971 23.913 11.2669 23.913 12.1591Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M24.8004 13.9726C24.7632 13.9416 24.7335 13.9027 24.7133 13.8587C24.6932 13.8147 24.6832 13.7668 24.6841 13.7184C24.6856 13.6261 24.7235 13.5381 24.7895 13.4735C24.8555 13.4089 24.9443 13.3729 25.0366 13.3735C25.1296 13.3751 25.2183 13.4127 25.2842 13.4784C25.5107 13.7127 25.8203 13.8485 26.1461 13.8564C26.4684 13.8564 26.7387 13.7212 26.7387 13.4434C26.7387 13.2033 26.5213 13.106 26.2208 12.986L25.8428 12.8291C25.2502 12.5815 24.8826 12.3122 24.8826 11.7574C24.8826 11.0751 25.4496 10.6923 26.1801 10.6923C26.6132 10.6857 27.0316 10.8485 27.3463 11.146C27.3841 11.1785 27.4144 11.2188 27.4351 11.2642C27.4558 11.3095 27.4665 11.3588 27.4664 11.4087C27.4671 11.4971 27.4329 11.5822 27.3711 11.6454C27.3093 11.7086 27.2249 11.7448 27.1365 11.7461C27.0465 11.7375 26.962 11.6989 26.8965 11.6364C26.6888 11.4739 26.4334 11.3843 26.1698 11.3813C25.8768 11.3813 25.667 11.4937 25.667 11.7338C25.667 11.936 25.8172 12.0334 26.1546 12.1685L26.5071 12.3112C27.1743 12.5881 27.5117 12.8782 27.5117 13.4207C27.5117 14.1626 26.8814 14.5444 26.1471 14.5444C25.8952 14.5476 25.6454 14.4985 25.4136 14.4001C25.1818 14.3017 24.9729 14.1561 24.8004 13.9726Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M30.6824 14.138C30.6864 14.2117 30.6652 14.2845 30.6222 14.3445C30.5793 14.4044 30.5172 14.4479 30.4461 14.4678C30.2878 14.5247 30.1199 14.55 29.9519 14.5425C29.0749 14.5425 28.5948 14.0927 28.5948 13.261V11.4654H28.3027C28.2138 11.469 28.1271 11.4376 28.0611 11.3778C27.9952 11.3181 27.9554 11.2348 27.9502 11.146V11.1327C27.9496 11.0868 27.9581 11.0413 27.9753 10.9987C27.9924 10.9561 28.0179 10.9174 28.0502 10.8848C28.0825 10.8521 28.1209 10.8262 28.1633 10.8086C28.2057 10.791 28.2512 10.782 28.2971 10.7821H28.5995V10.1348C28.5969 10.0825 28.6045 10.0303 28.6221 9.98102C28.6397 9.93176 28.6668 9.88644 28.7019 9.84767C28.737 9.8089 28.7794 9.77743 28.8267 9.75507C28.874 9.7327 28.9252 9.71988 28.9775 9.71733C29.0297 9.71478 29.082 9.72256 29.1312 9.74022C29.1804 9.75788 29.2257 9.78508 29.2644 9.82025C29.3031 9.85542 29.3345 9.89788 29.3568 9.9452C29.3791 9.99252 29.3918 10.0438 29.3943 10.096C29.3952 10.1089 29.3952 10.1219 29.3943 10.1348V10.7821H30.1815C30.2263 10.7806 30.271 10.7879 30.313 10.8037C30.3549 10.8195 30.3934 10.8433 30.4261 10.874C30.4589 10.9046 30.4852 10.9414 30.5038 10.9822C30.5223 11.0231 30.5326 11.0671 30.534 11.112V11.1271C30.5348 11.2156 30.5004 11.3009 30.4384 11.3641C30.3764 11.4274 30.2918 11.4635 30.2032 11.4645H29.3933V13.1939C29.3933 13.6428 29.6107 13.8309 30.1125 13.8309C30.207 13.8309 30.2703 13.8233 30.3374 13.8233C30.5472 13.8233 30.6824 13.9509 30.6824 14.138Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M34.3256 12.2337V14.1456C34.3281 14.1951 34.3209 14.2447 34.3043 14.2915C34.2876 14.3383 34.262 14.3813 34.2287 14.4182C34.1954 14.4551 34.1552 14.485 34.1104 14.5063C34.0656 14.5276 34.017 14.5399 33.9674 14.5425H33.9362C33.8344 14.544 33.7361 14.505 33.6629 14.4342C33.5897 14.3633 33.5476 14.2663 33.5459 14.1645V14.1011C33.2766 14.3846 32.8844 14.5434 32.4118 14.5434C31.6473 14.5434 31.1294 14.1163 31.1294 13.4793C31.1294 12.8423 31.6548 12.4293 32.4865 12.4293H33.5261V12.1855C33.5261 11.6827 33.2425 11.3983 32.7388 11.3983C32.5863 11.3973 32.4352 11.4274 32.2946 11.4867C32.1541 11.546 32.0271 11.6333 31.9214 11.7432C31.8494 11.8149 31.7535 11.8577 31.652 11.8632C31.5643 11.8617 31.4805 11.8268 31.4175 11.7658C31.3545 11.7047 31.317 11.622 31.3127 11.5344C31.3155 11.4728 31.3309 11.4124 31.3578 11.357C31.3848 11.3016 31.4229 11.2523 31.4696 11.2121C31.7843 10.8813 32.272 10.6942 32.8116 10.6942C33.7633 10.697 34.3256 11.2669 34.3256 12.2337ZM33.5317 13.0663V12.9983H32.5867C32.1595 12.9983 31.9346 13.1561 31.9346 13.4481C31.9346 13.7401 32.1897 13.9357 32.5791 13.9357C33.1112 13.9357 33.5317 13.553 33.5317 13.0663Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M38.6369 12.1591V14.138C38.642 14.2424 38.6055 14.3446 38.5353 14.422C38.4651 14.4995 38.3671 14.5459 38.2627 14.551C38.1583 14.5561 38.0561 14.5196 37.9787 14.4494C37.9012 14.3792 37.8548 14.2812 37.8497 14.1768C37.8487 14.1639 37.8487 14.1509 37.8497 14.138V12.264C37.8497 11.7546 37.5274 11.4134 37.0473 11.4134C36.9399 11.4105 36.8329 11.4289 36.7326 11.4673C36.6322 11.5058 36.5405 11.5637 36.4625 11.6377C36.3846 11.7117 36.322 11.8004 36.2783 11.8986C36.2347 11.9968 36.2108 12.1027 36.2081 12.2101C36.2072 12.228 36.2072 12.246 36.2081 12.264V14.138C36.2151 14.238 36.1821 14.3367 36.1164 14.4124C36.0506 14.4881 35.9575 14.5345 35.8575 14.5416H35.8178C35.7664 14.5423 35.7154 14.5329 35.6676 14.5139C35.6199 14.495 35.5763 14.4668 35.5395 14.431C35.5026 14.3952 35.4732 14.3525 35.4528 14.3053C35.4325 14.2581 35.4216 14.2074 35.4209 14.156V11.1025C35.4157 10.9991 35.4519 10.8979 35.5214 10.8212C35.5908 10.7444 35.688 10.6984 35.7913 10.6933C35.8947 10.6882 35.9959 10.7243 36.0727 10.7938C36.1494 10.8632 36.1954 10.9604 36.2006 11.0638C36.2015 11.0767 36.2015 11.0896 36.2006 11.1025C36.4855 10.8333 36.8652 10.6875 37.2571 10.6971C38.1011 10.6971 38.6369 11.2669 38.6369 12.1591Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M42.0004 14.138C42.0044 14.2111 41.9837 14.2835 41.9415 14.3434C41.8993 14.4032 41.8381 14.4471 41.7679 14.4678C41.6092 14.5246 41.441 14.55 41.2727 14.5425C40.3957 14.5425 39.9165 14.0926 39.9165 13.261V11.4654H39.6235C39.5336 11.4687 39.446 11.4363 39.38 11.3752C39.3139 11.3141 39.2747 11.2293 39.271 11.1394V11.128C39.271 11.0363 39.3075 10.9483 39.3724 10.8834C39.4372 10.8186 39.5252 10.7821 39.6169 10.7821H39.9165V10.1348C39.9137 10.0302 39.9524 9.92879 40.0241 9.85266C40.0958 9.77652 40.1947 9.73185 40.2993 9.72839H40.3087C40.3601 9.72751 40.4112 9.73681 40.459 9.75573C40.5068 9.77466 40.5504 9.80285 40.5873 9.83868C40.6242 9.87451 40.6536 9.91728 40.6739 9.96452C40.6943 10.0118 40.705 10.0626 40.7056 10.114V10.7755H41.4929C41.5377 10.774 41.5823 10.7813 41.6243 10.7971C41.6663 10.8128 41.7047 10.8367 41.7375 10.8674C41.7702 10.898 41.7966 10.9348 41.8151 10.9756C41.8336 11.0164 41.8439 11.0605 41.8454 11.1053V11.1205C41.8461 11.209 41.8117 11.2943 41.7497 11.3575C41.6877 11.4208 41.6032 11.4568 41.5146 11.4578H40.7056V13.1873C40.7056 13.6362 40.923 13.8243 41.4258 13.8243C41.5155 13.8243 41.5826 13.8167 41.6507 13.8167C41.8605 13.8233 42.0004 13.9509 42.0004 14.138Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M42.6373 13.7855C42.5647 13.7111 42.5219 13.6125 42.5173 13.5086C42.5169 13.461 42.526 13.4139 42.5439 13.3698C42.5618 13.3257 42.5882 13.2856 42.6217 13.2518C42.6552 13.218 42.695 13.1911 42.7388 13.1728C42.7827 13.1544 42.8298 13.1449 42.8774 13.1447H42.8878C42.933 13.1437 42.9779 13.1525 43.0194 13.1706C43.0609 13.1887 43.0979 13.2157 43.1278 13.2497C43.264 13.425 43.4375 13.5679 43.6358 13.6677C43.8341 13.7676 44.0522 13.8221 44.2742 13.8271C44.7996 13.8271 45.1294 13.5492 45.1294 13.1372C45.1294 12.8149 44.9489 12.6495 44.4017 12.3953L43.8545 12.1402C43.0673 11.7725 42.7006 11.3605 42.7006 10.7689C42.7006 9.98921 43.3678 9.42691 44.259 9.42691C44.5178 9.41794 44.7753 9.46566 45.0137 9.56671C45.252 9.66776 45.4654 9.81971 45.6388 10.0119C45.7138 10.0875 45.7568 10.189 45.7588 10.2954C45.7525 10.3894 45.7113 10.4777 45.6433 10.5429C45.5753 10.6081 45.4854 10.6455 45.3912 10.6479C45.3011 10.6434 45.2157 10.6061 45.1512 10.543C45.0379 10.4183 44.9003 10.318 44.7469 10.2483C44.5935 10.1786 44.4275 10.1409 44.259 10.1376C43.8101 10.1376 43.5096 10.3701 43.5096 10.7377C43.5096 11.0448 43.6599 11.1875 44.1995 11.4418L44.7542 11.6969C45.5793 12.0797 45.9384 12.4766 45.9384 13.106C45.9384 13.9679 45.2419 14.5378 44.2817 14.5378C43.9699 14.5362 43.6621 14.4682 43.3786 14.3385C43.0951 14.2088 42.8424 14.0203 42.6373 13.7855Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M46.6737 12.6164C46.673 12.3648 46.722 12.1156 46.8178 11.8829C46.9135 11.6503 47.0542 11.4387 47.2318 11.2605C47.4093 11.0822 47.6203 10.9407 47.8526 10.844C48.0848 10.7473 48.3339 10.6974 48.5855 10.697H48.6224C49.2678 10.697 49.8226 11.0042 50.107 11.4464C50.1535 11.5067 50.1797 11.5802 50.1817 11.6563C50.1826 11.7027 50.1743 11.7488 50.1573 11.792C50.1404 11.8352 50.1151 11.8747 50.0829 11.9081C50.0507 11.9416 50.0122 11.9683 49.9696 11.9869C49.9271 12.0055 49.8813 12.0155 49.8349 12.0163H49.8292C49.7762 12.0187 49.7234 12.0084 49.6752 11.9862C49.627 11.964 49.5849 11.9306 49.5523 11.8887C49.4447 11.7458 49.3054 11.6299 49.1453 11.5502C48.9852 11.4705 48.8088 11.4291 48.6299 11.4294C47.9684 11.4294 47.476 11.9388 47.476 12.6212C47.476 13.3177 47.9627 13.8204 48.6299 13.8204C48.8082 13.8189 48.9839 13.7769 49.1436 13.6977C49.3034 13.6185 49.4431 13.504 49.5523 13.363C49.5844 13.3206 49.6264 13.2868 49.6747 13.2645C49.723 13.2423 49.7761 13.2323 49.8292 13.2354C49.8754 13.2353 49.9211 13.2443 49.9638 13.2618C50.0065 13.2794 50.0453 13.3052 50.0781 13.3377C50.1108 13.3703 50.1368 13.409 50.1546 13.4516C50.1724 13.4942 50.1816 13.5399 50.1817 13.5861C50.1825 13.6591 50.1589 13.7303 50.1146 13.7883C49.9489 14.029 49.7254 14.2243 49.4648 14.3564C49.2041 14.4884 48.9145 14.5531 48.6224 14.5443C48.3705 14.5486 48.1203 14.5032 47.886 14.4106C47.6517 14.3181 47.438 14.1803 47.257 14.0051C47.076 13.8299 46.9313 13.6208 46.8312 13.3896C46.7311 13.1585 46.6776 12.9099 46.6737 12.658V12.6164Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M53.2219 11.1025C53.2219 11.2028 53.1821 11.2989 53.1112 11.3698C53.0403 11.4407 52.9442 11.4805 52.8439 11.4805C52.2447 11.4956 51.8289 11.902 51.8289 12.4927V14.138C51.8359 14.238 51.8029 14.3367 51.7372 14.4124C51.6714 14.4881 51.5783 14.5345 51.4783 14.5416H51.4386C51.3872 14.5423 51.3362 14.5329 51.2884 14.5139C51.2407 14.495 51.1971 14.4668 51.1603 14.431C51.1234 14.3952 51.094 14.3525 51.0736 14.3053C51.0533 14.2581 51.0424 14.2074 51.0417 14.156V11.1025C51.0365 10.9991 51.0727 10.8979 51.1422 10.8212C51.2116 10.7444 51.3088 10.6984 51.4121 10.6933C51.5155 10.6882 51.6167 10.7243 51.6935 10.7938C51.7702 10.8632 51.8162 10.9604 51.8214 11.0638C51.8223 11.0767 51.8223 11.0896 51.8214 11.1025V11.215C51.9473 11.0657 52.1045 10.946 52.2819 10.8644C52.4592 10.7827 52.6524 10.7411 52.8477 10.7424C52.9415 10.7381 53.0333 10.7712 53.1028 10.8344C53.1722 10.8977 53.2137 10.986 53.2181 11.0798L53.2219 11.1025Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M54.7775 9.40799C54.7804 9.51166 54.7526 9.61389 54.6974 9.70173C54.6423 9.78958 54.5623 9.85911 54.4677 9.90152C54.373 9.94394 54.2679 9.95734 54.1657 9.94004C54.0634 9.92273 53.9686 9.87549 53.8931 9.8043C53.8177 9.7331 53.7651 9.64114 53.7419 9.54004C53.7188 9.43895 53.7261 9.33325 53.763 9.23631C53.7999 9.13938 53.8647 9.05556 53.9492 8.99545C54.0337 8.93534 54.1342 8.90163 54.2379 8.8986H54.253C54.39 8.89683 54.5222 8.94945 54.6205 9.04493C54.7188 9.14041 54.7752 9.27096 54.7775 9.40799ZM53.8561 14.138V11.1025C53.8561 10.9982 53.8975 10.8982 53.9712 10.8245C54.0449 10.7507 54.1449 10.7093 54.2492 10.7093C54.3535 10.7093 54.4535 10.7507 54.5272 10.8245C54.6009 10.8982 54.6423 10.9982 54.6423 11.1025V14.138C54.6441 14.1894 54.6357 14.2406 54.6177 14.2887C54.5996 14.3369 54.5723 14.381 54.5372 14.4186C54.5021 14.4561 54.4599 14.4864 54.4131 14.5077C54.3663 14.5289 54.3157 14.5408 54.2643 14.5425H54.2454C54.1438 14.5435 54.0459 14.5043 53.973 14.4335C53.9001 14.3628 53.858 14.266 53.8561 14.1644C53.8554 14.1556 53.8554 14.1468 53.8561 14.138Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M59.6058 12.6165C59.6058 13.726 58.8412 14.5425 57.7988 14.5425C57.5676 14.5493 57.3376 14.5051 57.1253 14.4132C56.913 14.3212 56.7235 14.1837 56.5703 14.0104V15.4932C56.5728 15.5437 56.5653 15.5943 56.5483 15.6419C56.5313 15.6896 56.5051 15.7334 56.4711 15.7709C56.4371 15.8083 56.3961 15.8388 56.3504 15.8604C56.3046 15.882 56.255 15.8943 56.2045 15.8968H56.1809C56.1294 15.8975 56.0782 15.8881 56.0304 15.8691C55.9825 15.8501 55.9388 15.8218 55.9019 15.7859C55.8649 15.75 55.8354 15.7072 55.815 15.6599C55.7946 15.6126 55.7838 15.5617 55.783 15.5102V11.0874C55.7813 11.0366 55.7895 10.986 55.8073 10.9384C55.8251 10.8908 55.8521 10.8473 55.8868 10.8101C55.9215 10.773 55.9631 10.7431 56.0094 10.722C56.0556 10.701 56.1055 10.6893 56.1563 10.6876H56.1762C56.2265 10.6876 56.2764 10.6977 56.3228 10.7172C56.3692 10.7367 56.4113 10.7653 56.4465 10.8013C56.4817 10.8373 56.5094 10.88 56.5279 10.9269C56.5464 10.9737 56.5553 11.0238 56.5542 11.0741V11.2348C56.8245 10.8974 57.2668 10.6952 57.7913 10.6952C58.8412 10.6971 59.6058 11.5145 59.6058 12.6165ZM58.7959 12.624C58.7959 11.9266 58.3233 11.4172 57.6712 11.4172C57.0192 11.4172 56.5617 11.902 56.5617 12.6165C56.5617 13.3309 57.0021 13.8233 57.6712 13.8233C58.3252 13.8233 58.7977 13.313 58.7977 12.624H58.7959Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M62.7764 14.138C62.7805 14.2111 62.7597 14.2835 62.7176 14.3434C62.6754 14.4032 62.6142 14.4471 62.5439 14.4678C62.3856 14.5247 62.2177 14.55 62.0497 14.5425C61.1727 14.5425 60.6926 14.0926 60.6926 13.261V11.4654H60.4091C60.3641 11.4678 60.319 11.4613 60.2766 11.4461C60.2342 11.431 60.1951 11.4076 60.1618 11.3773C60.1285 11.3469 60.1015 11.3103 60.0825 11.2694C60.0634 11.2286 60.0527 11.1844 60.0509 11.1394V11.128C60.0509 11.0363 60.0873 10.9483 60.1522 10.8834C60.2171 10.8186 60.305 10.7821 60.3968 10.7821H60.6954V10.1348C60.6942 10.082 60.7034 10.0295 60.7227 9.9804C60.742 9.93127 60.7708 9.88648 60.8076 9.84864C60.8444 9.8108 60.8884 9.78068 60.9369 9.76003C60.9855 9.73938 61.0377 9.72863 61.0905 9.72839C61.1419 9.72751 61.1929 9.73681 61.2408 9.75573C61.2886 9.77466 61.3322 9.80285 61.3691 9.83868C61.4059 9.87451 61.4354 9.91728 61.4557 9.96452C61.476 10.0118 61.4868 10.0626 61.4874 10.114V10.7755H62.2746C62.3194 10.774 62.3641 10.7813 62.4061 10.7971C62.448 10.8128 62.4865 10.8367 62.5192 10.8674C62.552 10.898 62.5783 10.9348 62.5968 10.9756C62.6154 11.0164 62.6256 11.0605 62.6271 11.1053V11.1205C62.6279 11.209 62.5935 11.2943 62.5315 11.3575C62.4695 11.4208 62.3849 11.4568 62.2963 11.4578H61.4874V13.1873C61.4874 13.6362 61.7047 13.8243 62.2075 13.8243C62.2973 13.8243 62.3644 13.8167 62.4315 13.8167C62.476 13.8116 62.5211 13.8162 62.5637 13.8302C62.6062 13.8442 62.6452 13.8673 62.678 13.8978C62.7108 13.9284 62.7366 13.9657 62.7536 14.0071C62.7706 14.0486 62.7784 14.0932 62.7764 14.138Z",
    fill: "#0065F2"
  }), ___EmotionJSX("path", {
    d: "M63.2867 13.9726C63.2503 13.9413 63.2212 13.9022 63.2018 13.8582C63.1823 13.8142 63.1729 13.7664 63.1743 13.7184C63.1758 13.6267 63.2131 13.5393 63.2783 13.4748C63.3435 13.4103 63.4313 13.3739 63.523 13.3734C63.6159 13.3755 63.7045 13.413 63.7706 13.4783C63.9969 13.7129 64.3066 13.8487 64.6325 13.8564C64.9548 13.8564 65.2241 13.7212 65.2241 13.4434C65.2241 13.2033 65.0067 13.106 64.7072 12.986L64.3291 12.8291C63.7375 12.5815 63.3699 12.3121 63.3699 11.7574C63.3699 11.0751 63.9369 10.6923 64.6665 10.6923C65.1008 10.685 65.5207 10.8478 65.8365 11.1459C65.8743 11.1785 65.9045 11.2188 65.9253 11.2642C65.946 11.3095 65.9566 11.3588 65.9565 11.4087C65.9563 11.4964 65.9216 11.5806 65.8599 11.643C65.7982 11.7054 65.7144 11.741 65.6267 11.7423C65.5364 11.7337 65.4515 11.6951 65.3857 11.6326C65.178 11.4701 64.9227 11.3805 64.659 11.3775C64.366 11.3775 64.1562 11.4899 64.1562 11.73C64.1562 11.9322 64.3065 12.0296 64.6438 12.1647L64.9954 12.3074C65.6626 12.5843 66.0047 12.8744 66.0047 13.4141C66.0047 14.1559 65.3753 14.5377 64.641 14.5377C64.3884 14.5429 64.1375 14.4953 63.9043 14.398C63.6711 14.3007 63.4608 14.1558 63.2867 13.9726Z",
    fill: "#0065F2"
  })), ___EmotionJSX("defs", null, ___EmotionJSX("clipPath", {
    id: "icon-instant-scripts-clip"
  }, ___EmotionJSX("rect", {
    fill: "white",
    height: "16",
    transform: "translate(2 4)",
    width: "64"
  }))))
});